<template>
  <b-modal
    id="blog-edit-modal"
    title="Blog Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Blog Edit</h2>
    </template>
    <validation-observer ref="blogEditFormValidation">
      <b-form @submit.prevent="validationForm">
        <b-form-group label-for="blog_category">
          <template #label>
            Blog Category <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Category"
            rules="required"
          >
            <v-select
              label="name"
              v-model="blogCategory"
              placeholder="Select Blog Category"
              :options="blogCategories"
              :reduce="(option) => option.id"
              autoscroll
              :clearable="true"
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="intro">
          <template #label>
            Introduction <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="intro"
            v-model="intro"
            placeholder="Introduction"
            required
            @keydown="checkMaxLength($event, 'intro', 400)"
          />
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Description </template>
          <quill-editor id="description" v-model="description" />
        </b-form-group>
        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group label-for="image">
          <template #label> Image <span class="text-danger">*</span> </template>
          <div v-if="image" class="mb-2">
            <img
              :src="image"
              alt="Blog Image"
              class="img-thumbnail"
              style="max-width: 15%; margin-bottom: 10px"
            />
          </div>
          <validation-provider v-slot="{ errors }" name="Image">
            <b-form-group
              class="form-group"
              id="input-group-2"
              label-for="input-2"
              description="Upload an image in PNG, JPG, or JPEG format."
              :invalid-feedback="errors[0]"
            >
              <b-form-file
                ref="imageInput"
                id="documents"
                placeholder="Choose a PNG, JPG, or JPEG file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".png,.jpg,.jpeg"
                plain
                v-model="files"
                @change="validateFileType($event, 'files')"
              ></b-form-file>
            </b-form-group>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="info" pill class="mr-1">
            Update
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BModal,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BModal,
  },
  props: {
    blog: Object,
  },
  data() {
    return {
      blogCategory: null,
      blogCategories: [],
      intro: "",
      image: null,
      description: "",
      status: "",
      regex: /^[a-zA-Z ]*$/,
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
    };
  },
  async mounted() {
    const res_blogCategories = await this.getBlogCategories();
    this.blogCategories = res_blogCategories.data;

    if (this.blog) {
      this.blogCategory = this.blog.category;
      this.intro = this.blog.intro;
      this.image = this.blog.image;
      this.description = this.blog.description;
      this.status = this.blog.status;
      this.image = this.blog.image;
    }
  },
  methods: {
    ...mapActions({
      updateBlog: "appData/updateBlog",
      getBlogCategories: "appData/getBlogCategoryPublic",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async validationForm() {
      const success = await this.$refs.blogEditFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },

    validateFileType(event) {
      const file = event.target.files[0];
      if (!file) return;

      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        event.target.value = null;
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity(
            "Please upload a PNG, JPG, or JPEG image file."
          );
          this.$refs.imageInput.$el.reportValidity();
        }
      } else {
        if (this.$refs.imageInput) {
          this.$refs.imageInput.$el.setCustomValidity("");
        }
      }
    },
    async submit() {
      try {
        const formData = new FormData();
        formData.append("category", this.blogCategory);
        formData.append("intro", this.intro);
        if (this.files instanceof File) {
          formData.append("image", this.files);
        }
        formData.append("description", this.description);
        formData.append("status", this.status);
        formData.append("updated_by", this.getLoggedInUser.id);

        const res = await this.updateBlog({
          payload: formData,
          pk: this.blog.id,
        });

        if (res.status === 200) {
          this.$swal({
            title: "Blog updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("blog-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.blogCategory = null;
      this.intro = "";
      this.image = null;
      this.description = "";
      this.status = "";
    },
    displayError(error) {
      console.error(error);
      this.$swal({
        title: "Error",
        text: error.message,
        icon: "error",
      });
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style></style>
